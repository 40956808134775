import { render, staticRenderFns } from "./select-09.vue?vue&type=template&id=1649f6bc&scoped=true&"
import script from "./select-09.vue?vue&type=script&lang=js&"
export * from "./select-09.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1649f6bc",
  null
  
)

export default component.exports